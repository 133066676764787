/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css";

//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";

// Near entry of your product, init Mixpanel
mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN, {
  debug: process.env.GATSBY_ENV === "development",
  track_pageview: true,
  persistence: "cookie",
  cross_subdomain_cookie: true,
  cookie_domain:
    process.env.GATSBY_ENV === "development" ? "localhost" : "louper.io",
  secure_cookie: process.env.GATSBY_ENV !== "development",
});
// mixpanel.identify("guest-" + uuidv4());

export const onRouteUpdate = ({ location }) => {
  // Track page views on route updates
  if (typeof window !== "undefined" && window.mixpanel) {
    mixpanel.track("Page View", {
      page: location.pathname,
    });
  }
};

// export const shouldUpdateScroll = () => {
//   return false;
// };
